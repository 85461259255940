/* eslint-disable react/no-danger */
import classNames from 'classnames';
import { Headshot } from '@medifind/shared-basic-components';
import styles from './SearchSuggestion.module.scss';

const SearchSuggestion = ({ suggestion, className }) => {
  return (
    <div className={classNames(styles['suggestion'], styles['suggestion--experts'], className)}>
      <div className={styles['suggestion__image']}>
        <Headshot doctor={suggestion} micro={true} />
      </div>
      <div>
        <div>
          {suggestion.highlight ? (
            <span className={styles['suggestion__highlight']} dangerouslySetInnerHTML={{ __html: suggestion.highlight }} />
          ) : null}
        </div>
        <div>
          {suggestion.primarySpecialty?.[0] && <span>{suggestion.primarySpecialty?.[0]}</span>}
          {suggestion.location && suggestion.primarySpecialty?.[0] && ' - '}
          {suggestion.location ? (
            <span
              dangerouslySetInnerHTML={{
                __html: suggestion.location,
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { SearchSuggestion as default, SearchSuggestion };
