import { clientFetch } from '../clientFetch';

export const magicFilter = (params, options) => {
  return clientFetch(
    '/autocomplete/magic',
    {
      params,
    },
    options?.controller,
  );
};
export const magicAutocomplete = (params, signal) => {
  return clientFetch('/autocomplete/magic', {
    params,
    signal,
  });
};
